import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import sysSettings from "./syssettings.module";
import form from "./form.module";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		htmlClass,
		config,
		breadcrumbs,
		profile,
		sysSettings,
		form
	}
});
