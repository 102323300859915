import Vue from "vue";
import Router from "vue-router";
import i18n from "@/core/plugins/vue-i18n";

Vue.use(Router);

export default new Router({
	base: "/",
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/dashboard",
			component: () => import("@/view/layout/Layout"),
			children: [
				{
					path: "/dashboard",
					name: "dashboard",
					component: () => import("@/view/pages/Dashboard.vue"),
					meta: {
						title: i18n.t("MENU.DASHBOARD")
					}
				},
				{
					path: "/user-management",
					name: "user-management",
					component: () => import("@/view/pages/user_n_role_management/UserRoleManagement.vue"),
					children: [
						{
							path: "user",
							redirect: "user/index",
							name: "user",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/user_n_role_management/user/index.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.USER.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/user_n_role_management/user/create.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.USER.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/user_n_role_management/user/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.USER.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/user_n_role_management/user/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.USER.EDIT_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/order",
					name: "order",
					component: () => import("@/view/pages/order/Order.vue"),
					children: [
						{
							path: "inbound",
							redirect: "inbound/index",
							name: "inbound",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/order/inbound/index.vue"),
									meta: {
										title: i18n.t("ORDER.INBOUND.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/order/inbound/detail.vue"),
									meta: {
										title: i18n.t("ORDER.INBOUND.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/order/inbound/detail.vue"),
									meta: {
										title: i18n.t("ORDER.INBOUND.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/order/inbound/detail.vue"),
									meta: {
										title: i18n.t("ORDER.INBOUND.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "order-receive",
							redirect: "order-receive/index",
							name: "order-receive",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/order/order_receive/index.vue"),
									meta: {
										title: i18n.t("ORDER.ORDER_RECEIVING.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/order/order_receive/create.vue"),
									meta: {
										title: i18n.t("ORDER.ORDER_RECEIVING.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/order/order_receive/detail.vue"),
									meta: {
										title: i18n.t("ORDER.ORDER_RECEIVING.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/order/order_receive/detail.vue"),
									meta: {
										title: i18n.t("ORDER.ORDER_RECEIVING.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "epo-listing",
							redirect: "epo-listing/index",
							name: "epo-listing",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/order/epo_listing/index.vue"),
									meta: {
										title: i18n.t("EPO_MANAGEMENT.EPO_LISTING.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "payables-payment",
							redirect: "payables-payment/index",
							name: "payables-payment",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									name: "payables-payment-index",
									component: () => import("@/view/pages/order/payables_payment/index.vue"),
									meta: {
										title: i18n.t("PAYABLES_PAYMENT.LISTING_TITLE")
									}
								},
								{
									path: "detail/:id/:agent_id",
									name: "payables-payment-detail",
									component: () => import("@/view/pages/order/payables_payment/detail.vue"),
									meta: {
										title: i18n.t("PAYABLES_PAYMENT.PAYMENT_DETAILS")
									}
								},
								{
									path: "edit/:id/:agent_id",
									name: "payables-payment-edit",
									component: () => import("@/view/pages/order/payables_payment/detail.vue"),
									meta: {
										title: i18n.t("PAYABLES_PAYMENT.EDIT_PAYMENT")
									}
								},
								{
									path: "create",
									name: "payables-payment-create",
									component: () => import("@/view/pages/order/payables_payment/detail.vue"),
									meta: {
										title: i18n.t("PAYABLES_PAYMENT.CREATE_PAYMENT")
									}
								}
							]
						},
					]
				},
				{
					path: "/master-data",
					name: "master-data",
					component: () => import("@/view/pages/master_data/MasterData.vue"),
					children: [
						{
							path: "warehouse-location",
							redirect: "warehouse-location/index",
							name: "warehouse-location",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/warehouse_location/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE_LOCATION.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/warehouse_location/create.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE_LOCATION.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/warehouse_location/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE_LOCATION.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/warehouse_location/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE_LOCATION.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "maintain-agent",
							redirect: "maintain-agent/index",
							name: "maintain-agent",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/maintain_agent/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.MAINTAIN_AGENT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/maintain_agent/create.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.MAINTAIN_AGENT.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/maintain_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.MAINTAIN_AGENT.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/maintain_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.MAINTAIN_AGENT.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "warehouse",
							redirect: "warehouse/index",
							name: "warehouse",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/warehouse/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.WAREHOUSE.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "category",
							redirect: "category/parent/index",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "parent",
									redirect: "parent/index",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									children: [
										{
											path: "index",
											name: "category-parent-index",
											component: () =>
												import("@/view/pages/master_data/shipment/category/index_parent.vue"),
											meta: {
												title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY_PARENT.LISTING_TITLE")
											}
										},
									]
								},
								{
									path: "child",
									redirect: "child/index",
									component: {
										render(c) {
											return c("router-view");
										}
									},
									children: [
										{
											path: "index",
											name: "category-child-index",
											component: () =>
												import("@/view/pages/master_data/shipment/category/index.vue"),
											meta: {
												title: i18n.t("PRODUCT_MANAGEMENT.CATEGORY.LISTING_TITLE")
											}
										},
									]
								}
							]
						},
						{
							path: "category-type",
							redirect: "category-type/index",
							name: "category-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/shipment/category_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CATEGORY_TYPE.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "shipment-mode",
							redirect: "shipment-mode/index",
							name: "shipment-mode",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/shipment/shipment_mode/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_MODE.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "shipment-terms",
							redirect: "shipment-terms/index",
							name: "shipment-terms",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/shipment/shipment_terms/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_TERMS.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "notice-type",
							redirect: "notice-type/index",
							name: "notice-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/transaction/notice_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.NOTICE_TYPE.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "notice-type-group",
							redirect: "notice-type-group/index",
							name: "notice-type-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/transaction/notice_type_group/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.NOTICE_TYPE_GROUP.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "company",
							redirect: "company/index",
							name: "company",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/company/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COMPANY.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "supplier",
							redirect: "supplier/index",
							name: "supplier",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/supplier/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SUPPLIER.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/supplier/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SUPPLIER.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/supplier/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SUPPLIER.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/supplier/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SUPPLIER.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "doc-type",
							redirect: "doc-type/index",
							name: "doc-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/doc_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_TYPE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/transaction/doc_type/create.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_TYPE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/transaction/doc_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_TYPE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/transaction/doc_type/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_TYPE.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "doc-num",
							redirect: "doc-num/index",
							name: "doc-num",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/doc_num/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_NUM.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/master_data/transaction/doc_num/create.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_NUM.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/master_data/transaction/doc_num/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_NUM.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/master_data/transaction/doc_num/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DOC_NUM.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "countries",
							redirect: "countries/index",
							name: "countries",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/countries_region/countries/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COUNTRIES.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "states",
							redirect: "states/index",
							name: "states",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/countries_region/states/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.STATES.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "cities",
							redirect: "cities/index",
							name: "cities",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/countries_region/cities/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CITIES.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "district",
							redirect: "district/index",
							name: "district",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/countries_region/district/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.DISTRICT.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "postcode",
							redirect: "postcode/index",
							name: "postcode",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/countries_region/postcodes/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.POSTCODES.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "port",
							redirect: "port/index",
							name: "port",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/countries_region/port/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.PORT.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "currencies",
							redirect: "currencies/index",
							name: "currencies",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/currencies_tax/currencies/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CURRENCIES.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "uom",
							redirect: "uom/index",
							name: "uom",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/transaction/uom/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.UOM.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "ref-type",
							redirect: "ref-type/index",
							name: "ref-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/shipment/reference_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.REFERENCE_TYPE.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "shipper",
							redirect: "shipper/index",
							name: "shipper",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/shipper/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPPER.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "courier-company",
							redirect: "courier-company/index",
							name: "courier-company",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/shipment/courier_company/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.COURIER_COMPANY.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "shipping-line",
							redirect: "shipping-line/index",
							name: "shipping-line",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/shipment/shipping_line/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPPING_LINE.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "shipment-type",
							redirect: "shipment-type/index",
							name: "shipment-type",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/shipment/shipment_type/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.SHIPMENT_TYPE.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "consignee",
							redirect: "consignee/index",
							name: "consignee",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/consignee/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CONSIGNEE.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "consignee-group",
							redirect: "consignee-group/index",
							name: "consignee-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/shipment/consignee_group/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CONSIGNEE_GROUP.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "forwarder",
							redirect: "forwarder/index",
							name: "forwarder",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/master_data/shipment/forwarder/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.FORWARDER.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "forwarder-group",
							redirect: "forwarder-group/index",
							name: "forwarder-group",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/shipment/forwarder_group/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.FORWARDER_GROUP.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "delivery-agent",
							redirect: "delivery-agent/index",
							name: "delivery-agent",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/master_data/delivery_agent/index.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CONTAINER.DELIVERY_AGENT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () =>
										import("@/view/pages/master_data/delivery_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CONTAINER.DELIVERY_AGENT.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () =>
										import("@/view/pages/master_data/delivery_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CONTAINER.DELIVERY_AGENT.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () =>
										import("@/view/pages/master_data/delivery_agent/detail.vue"),
									meta: {
										title: i18n.t("MASTER_DATA.CONTAINER.DELIVERY_AGENT.DETAIL_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/data-filter-management",
					name: "data-filter-management",
					component: () => import("@/view/pages/user_n_role_management/UserRoleManagement.vue"),
					children: [
						{
							path: "data-filter",
							redirect: "data-filter/index",
							name: "data-filter",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/user_n_role_management/data_filter/index.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.DATA_FILTER.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () =>
										import("@/view/pages/user_n_role_management/data_filter/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.DATA_FILTER.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () =>
										import("@/view/pages/user_n_role_management/data_filter/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.DATA_FILTER.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () =>
										import("@/view/pages/user_n_role_management/data_filter/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.DATA_FILTER.EDIT_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/customer-management",
					name: "customer-management",
					component: () => import("@/view/pages/customer_management/CustomerManagement.vue"),
					children: [
						{
							path: "customer",
							redirect: "customer/index",
							name: "customer",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/customer_management/customer/index.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.CUSTOMER.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "delivery-address",
							redirect: "delivery-address/index",
							name: "delivery-address",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/customer_management/delivery_address/index.vue"),
									meta: {
										title: i18n.t("CUSTOMER_MANAGEMENT.DELIVERY_ADDRESS.LISTING_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/salesman-management",
					name: "salesman-management",
					component: () => import("@/view/pages/salesman_management/Master.vue"),
					children: [
						{
							path: "main-salesman",
							redirect: "main-salesman/index",
							name: "main-salesman",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/salesman_management/main_salesman/index.vue"),
									meta: {
										title: i18n.t("SALESMAN_MANAGEMENT.MAIN_SALESMAN.LISTING_TITLE")
									}
								},
							]
						},
						{
							path: "salesman",
							redirect: "salesman/index",
							name: "salesman",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/salesman_management/salesman/index.vue"),
									meta: {
										title: i18n.t("SALESMAN_MANAGEMENT.SALESMAN.LISTING_TITLE")
									}
								},
							]
						}
					]
				},
				{
					path: "/role-management",
					name: "role-management",
					component: () => import("@/view/pages/user_n_role_management/UserRoleManagement.vue"),
					children: [
						{
							path: "role",
							redirect: "role/index",
							name: "role",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/user_n_role_management/role/index.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.ROLE.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () => import("@/view/pages/user_n_role_management/role/create.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.ROLE.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/user_n_role_management/role/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.ROLE.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/user_n_role_management/role/detail.vue"),
									meta: {
										title: i18n.t("USER_N_ROLE_MANAGEMENT.ROLE.EDIT_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/settings",
					name: "settings",
					component: () => import("@/view/pages/settings/Settings.vue"),
					children: [
						{
							path: "general",
							redirect: "general/index",
							name: "general",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/general/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.GENERAL.TITLE")
									}
								}
							]
						},
						{
							path: "warehouse-setting",
							redirect: "warehouse-setting/index",
							name: "warehouse_setting",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/warehouse_settings/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.WAREHOUSE_SETTINGS.TITLE")
									}
								}
							]
						},
						{
							path: "agent",
							redirect: "agent/index",
							name: "agent",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/agent/index.vue"),
									meta: {
										title: i18n.t("SETTINGS.AGENT.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "language",
							redirect: "language/index",
							name: "language",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/settings/language/index.vue")
								},
								{
									path: "create",
									component: () => import("@/view/pages/settings/language/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.LANGUAGE.CREATE_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () => import("@/view/pages/settings/language/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.LANGUAGE.EDIT_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () => import("@/view/pages/settings/language/detail.vue"),
									meta: {
										title: i18n.t("SETTINGS.LANGUAGE.DETAIL_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/container",
					name: "container",
					component: () => import("@/view/pages/order/Order.vue"),
					children: [
						{
							path: "container-details",
							redirect: "container-details/index",
							name: "container-details",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/order/container/container_details/index.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.CONTAINER.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () =>
										import("@/view/pages/order/container/container_details/create.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.CONTAINER.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () =>
										import("@/view/pages/order/container/container_details/detail.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.CONTAINER.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () =>
										import("@/view/pages/order/container/container_details/detail.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.CONTAINER.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "container-loading",
							redirect: "container-loading/index",
							name: "container-loading",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/order/container/container_loading/index.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.LOADING.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () =>
										import("@/view/pages/order/container/container_loading/detail.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.LOADING.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () =>
										import("@/view/pages/order/container/container_loading/detail.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.LOADING.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () =>
										import("@/view/pages/order/container/container_loading/detail.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.LOADING.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "container-close",
							redirect: "container-close/index",
							name: "container-close",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/order/container/container_close/index.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.CLOSE.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "container-open",
							redirect: "container-open/index",
							name: "container-open",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/order/container/container_open/index.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.OPEN.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "container-reopen-so",
							redirect: "container-reopen-so/index",
							name: "container-reopen-so",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/order/container/container_reopen_so/index.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.REOPEN_SO.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () =>
										import("@/view/pages/order/container/container_reopen_so/create.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.REOPEN_SO.CREATE_TITLE")
									}
								}
							]
						},
						{
							path: "close-container-reopen-so",
							redirect: "close-container-reopen-so/index",
							name: "close-container-reopen-so",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/order/container/container_reopen_so_close/index.vue"),
									meta: {
										title: i18n.t("LOAD_CONTAINER.CLOSE_REOPEN_SO.LISTING_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/system-log",
					redirect: "/system-log/backend-api",
					component: () => import("@/view/pages/log/Log.vue"),
					children: [
						{
							path: "/",
							redirect: "/system-log/backend-api",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "backend-api",
									component: () => import("@/view/pages/log/log_summary.vue"),
									meta: {
										title: i18n.t("SYSTEM_LOG.BACKEND_API_LOG_TITLE")
									}
								},
								{
									path: "gim-api",
									component: () => import("@/view/pages/log/log_summary.vue"),
									meta: {
										title: i18n.t("SYSTEM_LOG.GIM_API_LOG_TITLE")
									}
								},
								{
									path: "sync-gim",
									component: () => import("@/view/pages/log/log_summary.vue"),
									meta: {
										title: i18n.t("SYSTEM_LOG.SYNC_GIM_LOG_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/print",
					name: "print",
					component: () => import("@/view/pages/print/Print.vue"),
					children: [
						{
							path: "packing-list",
							redirect: "packing-list/index",
							name: "packing-list",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/print/packing_list/index.vue"),
									meta: {
										title: i18n.t("PRINT.PACKING_LIST.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "start-stock-balance",
							redirect: "start-stock-balance/index",
							name: "start-stock-balance",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/print/start_stock_balance/index.vue"),
									meta: {
										title: i18n.t("PRINT.START_STOCK_BALANCE.LISTING_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/gimworld-order",
					name: "gimworld-order",
					component: () => import("@/view/pages/gimworld_order/GimworldOrder.vue"),
					children: [
						{
							path: "maintenance-list",
							redirect: "maintenance-list/index",
							name: "maintenance-list",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/gimworld_order/maintenance/index.vue"),
									meta: {
										title: i18n.t("GIMWORLD_ORDER.MAINTENANCE.LISTING_TITLE")
									}
								}
							]
						},
						{
							path: "post-order",
							redirect: "post-order/index",
							name: "post-order",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () => import("@/view/pages/gimworld_order/post_order/index.vue"),
									meta: {
										title: i18n.t("GIMWORLD_ORDER.POST_ORDER.LISTING_TITLE")
									}
								}
							]
						}
					]
				},
				{
					path: "/consignment",
					name: "consignment",
					component: () => import("@/view/pages/order/Order.vue"),
					children: [
						{
							path: "management",
							redirect: "management/index",
							name: "management",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import(
											"@/view/pages/order/consignment/manifest_consignment_management/index.vue"
										),
									meta: {
										title: i18n.t("ORDER.CONSIGNMENT.MANIFEST_CONSIGNMENT.LISTING_TITLE")
									}
								},
								{
									path: "create",
									component: () =>
										import(
											"@/view/pages/order/consignment/manifest_consignment_management/create.vue"
										),
									meta: {
										title: i18n.t("ORDER.CONSIGNMENT.MANIFEST_CONSIGNMENT.CREATE_TITLE")
									}
								},
								{
									path: "detail/:id",
									component: () =>
										import(
											"@/view/pages/order/consignment/manifest_consignment_management/create.vue"
										),
									meta: {
										title: i18n.t("ORDER.CONSIGNMENT.MANIFEST_CONSIGNMENT.DETAIL_TITLE")
									}
								},
								{
									path: "edit/:id",
									component: () =>
										import(
											"@/view/pages/order/consignment/manifest_consignment_management/create.vue"
										),
									meta: {
										title: i18n.t("ORDER.CONSIGNMENT.MANIFEST_CONSIGNMENT.EDIT_TITLE")
									}
								}
							]
						},
						{
							path: "delivery-agent-assignment",
							redirect: "delivery-agent-assignment/index",
							name: "delivery-agent-assignment-management",
							component: {
								render(c) {
									return c("router-view");
								}
							},
							children: [
								{
									path: "index",
									component: () =>
										import("@/view/pages/order/consignment/delivery_agent_assignment/index.vue"),
									meta: {
										title: i18n.t("ORDER.CONSIGNMENT.DELIVERY_AGENT_ASSIGNMENT.LISTING_TITLE")
									}
								}
							]
						}
						// {
						// 	path: "/consignment-agent-courier",
						// 	name: "consignment-agent-courier",
						// 	component: {
						// 		render(c) {
						// 			return c("router-view");
						// 		}
						// 	},
						// 	children: [
						// 		{
						// 			path: "management",
						// 			redirect: "management/index",
						// 			name: "management",
						// 			component: {
						// 				render(c) {
						// 					return c("router-view");
						// 				}
						// 			},
						// 			children: [
						// 				{
						// 					path: "index",
						// 					component: () =>
						// 						import(
						// 							"@/view/pages/order/consignment/consignment_agent_courier/index.vue"
						// 						),
						// 					meta: {
						// 						title: i18n.t("ORDER.CONSIGNMENT_AGENT_COURIER.LISTING_TITLE")
						// 					}
						// 				}
						// 			]
						// 		}
						// 	]
						// }
					]
				}
			]
		},
		{
			path: "/",
			component: () => import("@/view/pages/auth/login_pages/Login"),
			children: [
				{
					name: "login",
					path: "/login",
					component: () => import("@/view/pages/auth/login_pages/Login"),
					meta: {
						title: i18n.t("AUTH.LOGIN.TITLE")
					}
				}
			]
		},
		{
			name: "forgot-password",
			path: "/forgot-password/:vtoken",
			component: () => import("@/view/pages/auth/login_pages/ForgotPassword")
		},
		{
			path: "*",
			redirect: "/404"
		},
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			component: () => import("@/view/pages/error/Error-1.vue")
		}
	]
});
