<template>
	<v-data-table
		:items="items"
		:headers="headers"
		:footer-props="footer_props"
		:options.sync="options"
		class="vdatable mt-1"
		dense
		fixed-header
		:loading="loading"
		:value="selected_row"
		:server-items-length="total_record"
		:item-key="item_key == null ? `id` : item_key"
		v-model="vModel"
		:class="$Common.isMobile() ? `vdataMobile` : ``"
		:height="$Common.dataTableHeightHandle()"
		:single-select="multiSelect ? false : true"
		:show-select="disabled_select ? false : true"
		:hide-default-footer="hideFooter"
		:hide-default-header="hideHeader"
	>
		<template #header="{ props: { headers } }" v-if="['pn_order'].includes(module_name) && !$CoreSettingsJs.emptyObjectValidation(items)">
			<slot name="custom_header" v-bind="headers"></slot>
		</template>
		<template v-slot:item="{ item, index }" v-if="['delivery_agent', 'pn_payment', 'pn_order'].includes(module_name) && !$CoreSettingsJs.emptyObjectValidation(items)">
			<slot name="row_item" v-bind="{ item, index }"></slot>
		</template>

		<template v-for="(slot, name) in $scopedSlots" v-slot:[name]="header">
			<slot :name="header" v-bind="header"></slot>
		</template>

		<template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
			<slot :name="name" v-bind="item"></slot>
			<slot :name="item" v-bind="item"></slot>
		</template>

		<template v-slot:[`item.status`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.status)" size="sm">
				<span v-text="$Common.getStatusName(item.status)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.do_created`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.do_created)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.do_created)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.loading_status`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.loading_status)" size="sm">
				<span v-text="$Common.getIsOpenDesc(item.loading_status)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.display_loading_status`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.display_loading_status)" size="sm">
				<span v-text="$Common.getIsOpenDesc(item.display_loading_status)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.cl_loading_status`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.cl_loading_status == 0 ? 1 : 0)" size="sm">
				<span v-text="$Common.getIsOpenDesc(item.cl_loading_status == 0 ? 1 : 0)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.agent`]="{ item }">
			<span v-if="module_name !== 'maintain_agent'" v-text="item.agent"></span>
			<b-badge v-if="module_name == 'maintain_agent'" class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.agent)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.agent)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.checker`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.checker)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.checker)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.status_user`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.status)" size="sm">
				<span v-text="$Common.getStatusName(item.status)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.is_sensitive`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.is_sensitive)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.is_sensitive)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.received_amount`]="{ item }">
			<span v-text="$Common.numberFormatCurrency(item.received_amount)"></span>
		</template>
		<template v-slot:[`item.cash_payment`]="{ item }">
			<span v-text="$Common.numberFormatCurrency(item.cash_payment)"></span>
		</template>
		<template v-slot:[`item.my_paid`]="{ item }">
			<span v-text="$Common.numberFormatCurrency(item.my_paid)"></span>
		</template>
		<template v-slot:[`item.balance_amount`]="{ item }">
			<span v-text="$Common.numberFormatCurrency(item.balance_amount)"></span>
		</template>
		<template v-slot:[`item.deposit_amount`]="{ item }">
			<span v-text="$Common.numberFormatCurrency(item.deposit_amount)"></span>
		</template>
		<template v-slot:[`item.ttl_kg`]="{ item }">
			<span v-text="$Common.numberFormatM3(item.ttl_kg, 2)"></span>
		</template>
		<template v-slot:[`item.ttl_m3`]="{ item }">
			<span v-text="$Common.numberFormatM3(item.ttl_m3, 3)"></span>
		</template>
		<template v-slot:[`item.ttl_ctn`]="{ item }">
			<span v-text="$Common.numberFormatInteger(item.ttl_ctn)"></span>
		</template>
		<template v-slot:[`item.kg`]="{ item }">
			<span v-text="$Common.numberFormatM3(item.kg, 2)"></span>
		</template>
		<template v-slot:[`item.m3`]="{ item }">
			<span v-text="$Common.numberFormatM3(item.m3, 3)"></span>
		</template>
		<template v-slot:[`item.ctn`]="{ item }">
			<span v-text="$Common.numberFormatInteger(item.ctn, 0)"></span>
		</template>
		<template v-slot:[`item.total_quantity`]="{ item }">
			<span v-text="$Common.numberFormatCurrency(item.total_quantity)"></span>
		</template>
		<template v-slot:[`item.total_amount`]="{ item }">
			<span v-text="$Common.numberFormatCurrency(item.total_amount)"></span>
		</template>
		<template v-if="module_name == 'customer'" v-slot:[`item.main_salesman`]="{ item }">
			<span v-html="!$CoreSettingsJs.emptyObjectValidation(item.marking_set) ? item.marking_set.msm_code : ``"></span>
		</template>
		<template v-if="module_name == 'customer'" v-slot:[`item.salesman`]="{ item }">
			<span v-html="!$CoreSettingsJs.emptyObjectValidation(item.marking_set) ? item.marking_set.sm_code : ``"></span>
			<!-- <span v-html="item.marking_set.sm_code"></span> -->
		</template>
		<template v-if="module_name == 'customer'" v-slot:[`item.marking_id`]="{ item }">
			<span v-html="!$CoreSettingsJs.emptyObjectValidation(item.marking_set) ? item.marking_set.marking : ``"></span>
			<!-- <span v-html="item.marking_set.marking"></span> -->
		</template>
		<template v-if="module_name == 'customer'" v-slot:[`item.full_marking`]="{ item }">
			<span v-html="!$CoreSettingsJs.emptyObjectValidation(item.marking_set) ? item.marking_set.full_marking : ``"></span>
			<!-- <span v-html="item.marking_set.full_marking"></span> -->
		</template>
		<template v-if="module_name == 'customer'" v-slot:[`item.notice_type`]="{ item }">
			<span v-html="!$CoreSettingsJs.emptyObjectValidation(item.marking_set) ? item.marking_set.notice_type : ``"></span>
			<!-- <span v-html="item.marking_set.notice_type"></span> -->
		</template>
		<template v-slot:[`item.status`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.status, module_name)" size="sm">
				<span v-text="$CoreSettingsJs.handleStatusDesc(item.status, module_name)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.favourite`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.favourite)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.favourite)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.checker`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.checker)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.checker)"></span>
			</b-badge>
		</template>
		<template v-slot:[`item.marking_status`]="{ item }">
			<b-badge v-if="item.marking_status != null" class="text-center" :variant="markingStatusBadge(item.marking_status)" size="sm">
				<span v-text="markingStatusName(item.marking_status)"></span>
			</b-badge>
		</template>
		<template v-if="module_name == `docNum`" v-slot:[`item.num_by_company_group`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.num_by_company_group)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.num_by_company_group)"></span>
			</b-badge>
		</template>
		<template v-if="module_name == `docNum`" v-slot:[`item.num_reset`]="{ item }">
			<span v-text="getNumResetDesc(item.num_reset)"></span>
		</template>
		<template v-if="module_name == `localeSummary`" v-slot:[`item.image`]="{ item }">
			<span class="symbol symbol-20 mr-3">
				<img :src="item.image" alt="" />
			</span>
		</template>
		<template v-if="module_name == `noticeType`" v-slot:[`item.order_receipt`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.order_receipt)" size="sm">
				<span v-text="getOrderReceiptDesc(item.order_receipt)"></span>
			</b-badge>
		</template>
		<template v-if="module_name == `noticeType`" v-slot:[`item.cash_payment`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.cash_payment)" size="sm">
				<span v-text="getCashPaymentDesc(item.cash_payment)"></span>
			</b-badge>
		</template>
		<template v-if="module_name == `docType`" v-slot:[`item.use_same_id`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.use_same_id)" size="sm">
				<span v-text="getUseSameIdDesc(item.use_same_id)"></span>
			</b-badge>
		</template>
		<template v-if="module_name == `docType`" v-slot:[`item.posting_side`]="{ item }">
			<span v-text="getPostingSideDesc(item.posting_side)"></span>
		</template>
		<template v-if="module_name == `categoryType`" v-slot:[`item.use_for_invoice`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.use_for_invoice)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.use_for_invoice)"></span>
			</b-badge>
		</template>
		<template v-if="module_name == `uom`" v-slot:[`item.use_epo_inv_receive`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.use_epo_inv_receive)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.use_epo_inv_receive)"></span>
			</b-badge>
		</template>
		<template v-if="module_name == `uom`" v-slot:[`item.is_percentage`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.is_percentage)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.is_percentage)"></span>
			</b-badge>
		</template>
		<template v-if="module_name == `sensitiveType`" v-slot:[`item.use_for_receiving_epo`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.use_for_receiving_epo)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.use_for_receiving_epo)"></span>
			</b-badge>
		</template>
		<template v-if="module_name == `warehouseSettings`" v-slot:[`item.sticker_fee`]="{ item }">
			<span
				v-show="!$CoreSettingsJs.emptyStringValidation(item.sticker_fee_currency)"
				v-text="`${!$CoreSettingsJs.emptyStringValidation(item.sticker_fee_currency) ? item.sticker_fee_currency : ''} ${$Common.numberFormatCurrency(item.sticker_fee)}`"
			></span>
		</template>
		<template v-if="module_name == `users`" v-slot:[`item.roles`]="{ item }">
			<div class="d-flex">
				<b-badge class="mr-1" variant="secondary" v-for="(r, idx) in JSON.parse(item.roles)" :key="`role${idx}`">
					<span v-text="r"></span>
				</b-badge>
			</div>
		</template>
		<template v-if="module_name == `users`" v-slot:[`item.data_filters`]="{ item }">
			<div class="d-flex">
				<b-badge class="mr-1" variant="secondary" v-for="(d, idx) in JSON.parse(item.data_filters)" :key="`data_filters${idx}`">
					<span v-text="d"></span>
				</b-badge>
			</div>
		</template>
		<template v-if="module_name == `users`" v-slot:[`item.ignore_data_filter`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.ignore_data_filter)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.ignore_data_filter)"></span>
			</b-badge>
		</template>
		<template v-if="module_name == `inbound`" v-slot:[`item.ctn`]="{ item }">
			<span v-text="$Common.numberFormatInteger(item.ctn)"></span>
		</template>
		<template v-if="module_name == `inbound`" v-slot:[`item.plt`]="{ item }">
			<span v-text="$Common.numberFormatInteger(item.plt)"></span>
		</template>
		<template v-if="module_name == `epo`" v-slot:[`item.epo_type`]="{ item }">
			<span v-text="item.epo_type == 1 ? $t('EPO_MANAGEMENT.ORDER.EPO_TYPE_SELECTION_COURIER') : $t('EPO_MANAGEMENT.ORDER.EPO_TYPE_SELECTION_ORDER')"></span>
		</template>
		<template v-if="module_name == `epo`" v-slot:[`item.grand_total`]="{ item }">
			<span v-text="`${item.currency_code} ${$Common.numberFormatCurrency(item.grand_total)}`"></span>
		</template>
		<template v-if="module_name == `epo`" v-slot:[`item.total_deposit`]="{ item }">
			<span v-text="`${item.currency_code} ${$Common.numberFormatCurrency(item.total_deposit)}`"></span>
		</template>
		<template v-slot:[`item.request_plg`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.request_plg)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.request_plg)"></span>
			</b-badge>
		</template>

		<template v-if="module_name == `ref-id`" v-slot:[`item.total_m3`]="{ item }">
			<span v-text="`${$Common.numberFormatM3(item.total_m3)}`"></span>
		</template>
		<template v-if="module_name == `ref-id`" v-slot:[`item.total_ctns`]="{ item }">
			<span v-text="`${$Common.numberFormatInteger(item.total_ctns)}`"></span>
		</template>
		<template v-if="module_name == `ref-id`" v-slot:[`item.total_gross_weight`]="{ item }">
			<span v-text="`${$Common.numberFormatM3(item.total_gross_weight)}`"></span>
		</template>
		<template v-if="module_name == `ref-id`" v-slot:[`item.total_net_weight`]="{ item }">
			<span v-text="`${$Common.numberFormatM3(item.total_net_weight)}`"></span>
		</template>
		<template v-if="module_name == `ref-id`" v-slot:[`item.total_amt`]="{ item }">
			<span v-text="`${$Common.numberFormatCurrency(item.total_amt)}`"></span>
		</template>
		<template v-if="module_name == `gim-world-post-order`" v-slot:[`item.category_id`]="{ item }">
			<selectField
				:data="item.category_id"
				:selection="category_selections"
				:is_view="item.is_post == 1 ? 1 : 0"
				@update="
					(v) => {
						item.category_id = v;
						item.category_code = category_selections.find((x) => x.id == v).name;
					}
				"
			>
			</selectField>
		</template>
		<template v-if="module_name == 'gimworld_order_summary'" v-slot:[`item.is_post`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.is_post)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.is_post)"></span>
			</b-badge>
		</template>

		<template v-if="module_name == 'gimworld_order_summary'" v-slot:[`item.date`]="{ item }">
			<span v-text="$CoreSettingsJs.UnixTimeToDate(item.date)"></span>
		</template>
		<template v-if="module_name == 'gimworld_order_summary'" v-slot:[`item.created_at`]="{ item }">
			<span v-text="$CoreSettingsJs.UnixTimeToDateTime(item.created_at)"></span>
		</template>
		<template v-if="module_name == 'order-receiving'" v-slot:[`item.msm_code`]="{ item }">
			<span v-text="$Common.unserializeJsonStr(item.marking_set).msm_code"></span>
		</template>
		<template v-if="module_name == 'order-receiving'" v-slot:[`item.sm_code`]="{ item }">
			<span v-text="$Common.unserializeJsonStr(item.marking_set).sm_code"></span>
		</template>
		<template v-if="module_name == 'epo'" v-slot:[`item.status_name`]="{ item }">
			<span class="badge text-nowrap" :style="handleStatusStyle(item)">
				<span v-text="handleStatusDesc(item)"></span>
			</span>
		</template>
		<template v-if="module_name == `epo`" v-slot:[`item.epo_date`]="{ item }">
			<span v-text="$CoreSettingsJs.UnixTimeToDate(item.epo_date)"></span>
		</template>
		<template v-slot:[`item.has_tax_rebate`]="{ item }">
			<b-badge class="text-center" :variant="$CoreSettingsJs.handleBadgeVariant(item.has_tax_rebate)" size="sm">
				<span v-text="$CoreSettingsJs.handleYesNo(item.has_tax_rebate)"></span>
			</b-badge>
		</template>
	</v-data-table>
</template>

<script>
export default {
	name: "WmsServerMasterDataSummary",
	props: {
		items: null,
		headers: null,
		loading: false,
		total_record: 0,
		selected_row: [],
		module_name: null,
		multiSelect: {
			type: Boolean,
			default: false
		},
		disabled_select: {
			type: Boolean,
			default: false
		},
		item_key: null,
		form_valid_ref_id: [],
		hideFooter: false,
		hideHeader: false
	},
	data() {
		return {
			options: this.$CoreSettingsJs.datatableOptions,
			footer_props: this.$CoreSettingsJs.footerProps,
			category_selections: [],
			ref_id_selections: []
		};
	},
	mounted() {},
	computed: {
		vModel: {
			get() {
				return this.selected_row;
			},
			set(val) {
				this.$emit("rowSelected", val);
			}
		}
	},
	watch: {
		options: {
			handler() {
				this.$emit("searchRecord");
			},
			deep: true
		}
	},
	methods: {
		markingStatusBadge(status) {
			let status_option = this.$MasterDataJs.markingStatusSelection.find((data) => data.id == status);
			return status_option.color;
		},
		markingStatusName(status) {
			let status_option = this.$MasterDataJs.markingStatusSelection.find((data) => data.id == status);
			return status_option.name;
		},
		async init_getRefIds() {
			this.ref_id_selections = await this.$CoreJs.refIdSelection({
				loaded: 0,
				set_linkage: 0
			});
		},
		async init_getCategory() {
			this.category_selections = await this.$MasterDataJs.getSecondLevelCategoryAll();
		},
		validate_ref_ids(id) {
			if (this.form_valid_ref_id.some((x) => x.id == id)) return this.form_valid_ref_id.find((x) => x.id == id).result;
			return null;
		},
		handleStatusStyle(item) {
			let colorStyle;
			this.$emit("statusStyle", item, (result) => (colorStyle = result));
			return colorStyle;
		},
		handleStatusDesc(item) {
			let status_name;
			this.$emit("statusName", item, (result) => (status_name = result));
			return status_name;
		}
	}
};
</script>
