import i18n from "@/core/plugins/vue-i18n";
import i18nService from "@/core/services/i18n.service.js";
import CoreSettingsJs from "@shared/src/core/services/settings.js";
import Swal from "sweetalert2";


const yes_no_selection = [
	{
		item: null,
		name: i18n.t("COMMON.TXT_ALL")
	},
	{
		item: 1,
		name: i18n.t("COMMON.STATUS.YES")
	},
	{
		item: 0,
		name: i18n.t("COMMON.STATUS.NO")
	}
];

const yes_no_options = [
	{
		item: 1,
		name: i18n.t("COMMON.STATUS.YES")
	},
	{
		item: 0,
		name: i18n.t("COMMON.STATUS.NO")
	}
];

const selection_all = [
	{
		id: null,
		name: i18n.t("COMMON.TXT_ALL")
	}
];

const selection_options = [
	{
		id: null,
		name: i18n.t("COMMON.SELECTION_OPTION_TITLE")
	}
];

const footer_props = {
	"items-per-page-options": [20, 50, 100, { text: i18n.t("COMMON.TXT_ALL"), value: -1 }],
	showCurrentPage: true
};

const datatable_options = {
	page: 1,
	itemsPerPage: 20,
	sortBy: [],
	sortDesc: [],
	multiSort: true
};

const epo_status_options = [
	{
		item: null,
		name: i18n.t("COMMON.TXT_ALL")
	},
	{
		item: 1,
		name: i18n.t("COMMON.STATUS.ACTIVE")
	},
	{
		item: 0,
		name: i18n.t("COMMON.STATUS.DRAFT")
	},
	{
		item: 2,
		name: i18n.t("COMMON.STATUS.INACTIVE")
	}
];
const viewPage = function (path) {
	return path.indexOf("/detail") !== -1 ? 1 : 0;
};

const createPage = function (path) {
	return path.indexOf("create") !== -1 ? 1 : 0;
};

const UnixTimeToDateTime = function (unixtimestamp) {
	if (unixtimestamp == null) {
		return "";
	}
	//Convert timestamp to milliseconds
	var date = new Date(unixtimestamp);

	// Year
	var year = date.getFullYear();

	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	// Day
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// Hours
	var hours = date.getHours();

	// Minutes
	var minutes = "0" + date.getMinutes();

	// Seconds
	var seconds = "0" + date.getSeconds();

	// Display date time in MM-dd-yyyy h:m:s format
	var datetime = day + "/" + month + "/" + year + " " + hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
	return datetime;
};

const UnixTimeToDate = function (timstamp) {
	if (timstamp == null) {
		return "";
	}

	//Convert timestamp to milliseconds
	var date = new Date(timstamp);

	// Year
	var year = date.getFullYear();

	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	// Day
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// Display date time in MM-dd-yyyy h:m:s format
	var finalDate = year + "-" + month + "-" + day;

	return finalDate;
};

const getTodayDate = function () {
	let currentDate = new Date(),
		month = "" + (currentDate.getMonth() + 1),
		day = "" + currentDate.getDate(),
		year = currentDate.getFullYear();

	if (month.length < 2) {
		month = "0" + month;
	}

	if (day.length < 2) {
		day = "0" + day;
	}

	return [year, month, day].join("-");
};

const validateEmail = function (email) {
	if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
		return true;
	} else {
		return false;
	}
};

const UnixTimeToYM = function (timestamp) {
	//Convert timestamp to milliseconds
	var date = new Date(timestamp);

	// Year
	var year = date.getFullYear().toString().substr(-2);

	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	var finalDate = year + month;

	return finalDate;
};

const UnixTimeToYear = function (timestamp) {
	//Convert timestamp to milliseconds
	var date = new Date(timestamp);

	// Year
	var year = date.getFullYear().toString().substr(-2);
	return year;
};

const resetObServer = function (obj) {
	if (obj == null) {
		return null;
	}
	return JSON.parse(JSON.stringify(obj));
};

const ucword = function (value) {
	if (value == null) return null;
	value = value.toLowerCase();
	if (/\n/.test(value)) {
		return value
			.split("\n")
			.map((val1) =>
				val1
					.split(" ")
					.map((val) => val.charAt(0).toUpperCase() + val.slice(1))
					.join(" ")
			)
			.join("\n");
	} else {
		return value
			.split(" ")
			.map((val) => val.charAt(0).toUpperCase() + val.slice(1))
			.join(" ");
	}
};

const numberDecimal = function (number, decimal_place = 2) {
	return numberHandle(number).toFixed(decimal_place);
};

const numberFormatCurrency = function (number, decimal_place = 2) {
	let _number = CoreSettingsJs.emptyStringValidation(number, 1) ? 0 : number;
	// if (number == null || number.toString().trim() == "") number = 0;
	try {
		return new Intl.NumberFormat("en-US", { minimumFractionDigits: decimal_place }).format(
			numberHandle(_number).toFixed(decimal_place)
		);
	} catch {
		return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(
			numberHandle(_number).toFixed(2)
		);
	}
};

const dimensionFormat = function (number) {
	if (CoreSettingsJs.emptyStringValidation(number, 1)) number = 0;
	return new Intl.NumberFormat("en-US", { minimumFractionDigits: 4 }).format(parseFloat(number).toFixed(2));
};

const numberFormatInteger = function (number) {
	if (CoreSettingsJs.emptyStringValidation(number, 1)) return 0;
	return new Intl.NumberFormat("en-US").format(numberHandle(number).toFixed(0));
};

const numberFormatM3 = function (number, decimal_place = 4) {
	if (CoreSettingsJs.emptyStringValidation(number, 1)) {
		number = 0;
	}

	return new Intl.NumberFormat("en-Us", { minimumFractionDigits: decimal_place }).format(parseFloat(number));
};

const numberHandle = function (value) {
	if (CoreSettingsJs.emptyStringValidation(value)) {
		return parseFloat(0);
	}
	if (/,/.test(value)) {
		value = value.replaceAll(",", "");
	}

	return parseFloat(value);
};

const numberFormatToInteger = function (value) {
	if (value != null && /,/.test(value)) {
		value = value.replaceAll(",", "");
	}

	return parseInt(value);
};

const ucfirst = function (value) {
	if (/\n/.test(value)) {
		return value
			.split("\n")
			.map((val1) =>
				val1
					.split(" ")
					.map((val) => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase())
					.join(" ")
			)
			.join("\n");
	} else {
		return value
			.split(" ")
			.map((val) => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase())
			.join(" ");
	}
};

const convertNestedObjectToNull = function (object) {
	//NOTE: MAXIMUM 3 LEVEL ONLY
	let objectFormatted = object;
	if (objectFormatted !== undefined && objectFormatted != null) {
		if (
			typeof objectFormatted === "object" &&
			objectFormatted !== null &&
			Object.keys(objectFormatted).length > 0
		) {
			Object.keys(objectFormatted).forEach(function (key) {
				if (objectFormatted[key] !== undefined && objectFormatted[key] != null) {
					if (
						typeof objectFormatted[key] === "object" &&
						objectFormatted[key] !== null &&
						Object.keys(objectFormatted[key]).length > 0
					) {
						//second level convert
						Object.keys(objectFormatted[key]).forEach(function (key2) {
							if (objectFormatted[key][key2] !== undefined && objectFormatted[key][key2] != null) {
								if (
									typeof objectFormatted[key][key2] === "object" &&
									objectFormatted[key][key2] !== null &&
									Object.keys(objectFormatted[key][key2]).length > 0
								) {
									//third level convert
									Object.keys(objectFormatted[key][key2]).forEach(function (key3) {
										objectFormatted[key][key2][key3] = null;
									});
								} else {
									objectFormatted[key][key2] = null;
								}
							}
						});
					} else {
						objectFormatted[key] = null;
					}
				}
			});
		} else {
			objectFormatted = null;
		}
	}

	return objectFormatted;
};

const locale_list = i18nService.languages;

const setLanguageRecord = function (locales) {
	let records = [];
	for (let idx in locales) {
		records.push({
			locale_id: locales[idx].id,
			locale_name: locales[idx].name,
			locale: locales[idx].lang,
			value: null,
			value_state: null,
			value_error: null
		});
	}

	return records;
};

const getStatusBadge = function (status) {
	if (status != null && status == 1) {
		return "success";
	} else {
		return "danger";
	}
};

const getStatusName = function (status) {
	if (status == 1) {
		return i18n.t("COMMON.STATUS.ACTIVE");
	} else {
		return i18n.t("COMMON.STATUS.INACTIVE");
	}
};

const getYesNo = function (value) {
	if (value != null && value == 1) {
		return i18n.t("COMMON.STATUS.YES");
	} else {
		return i18n.t("COMMON.STATUS.NO");
	}
};
const getIsOpenDesc = function (status) {
	if (status == 1) {
		return i18n.t("COMMON.STATUS.CLOSED");
	} else {
		return i18n.t("COMMON.STATUS.OPEN");
	}
};

const getYesNoName = function (status) {
	if (status == 1) {
		return i18n.t("COMMON.STATUS.YES");
	} else {
		return i18n.t("COMMON.STATUS.NO");
	}
};

const editorSettings = {
	modules: {
		toolbar: {
			container: [
				[{ size: ["small", false, "large"] }],
				["bold", "italic", "underline", "strike"],
				["blockquote", "code-block"],
				[{ header: 1 }, { header: 2 }],
				[{ list: "ordered" }, { list: "bullet" }],
				[{ script: "sub" }, { script: "super" }],
				[{ indent: "-1" }, { indent: "+1" }],
				[{ direction: "rtl" }],
				[{ header: [1, 2, 3, 4, 5, 6, false] }],
				[{ color: [] }, { background: [] }],
				[{ font: [] }],
				[{ align: [] }],
				["clean"],
				["link", "image", "video"]
			]
		}
	}
};

const validateContactNo = function (calling_code, contact_no, return_msg, contact_no_label) {
	let return_err_msg = return_msg || false;
	let return_data = {
		status: false,
		message: ""
	};

	let full_contact = `${calling_code}${contact_no}`;

	let regex = /^\+(?:[0-9]?){6,14}[0-9]{10}$/;

	if (calling_code == "+60") {
		regex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$|^(\+60)[1-9]{1,2}-*[0-9]{6,8}$/;
	}

	if (regex.test(full_contact)) {
		return_data.status = true;
	} else {
		if (return_err_msg === true) {
			return_data.message = i18n.t("COMMON.VALIDATION.INVALID", { name: contact_no_label });

			if (calling_code == "+60") {
				return_data.message = i18n.t("COMMON.VALIDATION.INVALID_CONTACT_NO_FORMAT.FORMAT_MY", {
					name: contact_no_label
				});
			}
		}
	}

	if (return_err_msg === true) {
		return return_data;
	} else {
		return return_data.status;
	}
};

const filterActionField = function (is_view, fields) {
	if (is_view == 1) {
		return fields.filter((field) => field.key != "action");
	} else {
		return fields;
	}
};

const numberChecking = function (value) {
	if (value == null || value.toString().trim() == "") {
		return parseFloat(0);
	}

	if (value != null && /,/.test(value)) {
		value = value.replaceAll(",", "");
	}

	if (isNaN(parseFloat(value))) {
		return false;
	} else {
		return true;
	}
};

const onFormatterPhoneNumber = function (value, calling_code = "+60") {
	if (calling_code == "+86") {
		return value.replace(/\D/g, "");
	} else {
		if (value.charAt(0) == "0") {
			value = value.slice(1);
		}
		return value.replace(/\D/g, "");
	}
};

const onPressPhoneNumber = function (evt) {
	evt = evt ? evt : window.event;
	var charCode = evt.which ? evt.which : evt.keyCode;
	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		evt.preventDefault();
	} else {
		return true;
	}
};

const IntegerChecking = function (value) {
	if (value == null) {
		return parseInt(0);
	}

	if (value != null && /,/.test(value)) {
		value = value.replaceAll(",", "");
	}

	if (isNaN(parseInt(value))) {
		return false;
	} else {
		return true;
	}
};

const onFormatterAccountNo = function (value) {
	if (CoreSettingsJs.emptyStringValidation(value)) return value;
	value = value.replace(/\D/g, "");
	value = value.match(/.{1,4}/g);
	return value.join(" ");
};

const resetOptionsValue = function (data) {
	let options = [];

	for (let idx in data) {
		options.push({ value: data[idx].id, text: data[idx].name });
	}

	return options;
};

const checkResetSelection = function (selection, current_value, field_name = "id") {
	let reset = true;
	for (let idx in selection) {
		if (selection[idx][field_name] == current_value) {
			reset = false;
			break;
		}
	}
	return reset;
};

const checkResetSelectionObject = function (selection, current_value) {
	let resetVal = [];
	for (let idx in current_value) {
		let reset = true;
		for (let cidx in selection) {
			if (selection[cidx].id == current_value[idx]) {
				reset = false;
				break;
			}
		}
		if (reset) {
			resetVal.push(idx);
		}
	}

	if (resetVal.length > 0) {
		for (let idx in resetVal) {
			current_value.splice(resetVal[idx], 1);
		}
	}
	return current_value;
};

const numberRemoveNegativeValue = function (number) {
	if (isNaN(number)) {
		return 0;
	} else {
		return Math.abs(parseInt(number, 10));
	}
};

const getWordingFromi18n = function (locale, keys) {
	let wording = null;
	if (typeof i18n.messages[locale] != `undefined`) {
		let messages = null;
		for (let i = 0; i < keys.length; i++) {
			if (
				i18n.messages[locale][keys[i]] == `undefined` ||
				(messages != null && typeof messages[keys[i]] == "undefined")
			) {
				break;
			} else {
				if (parseInt(i) == parseInt(keys.length - 1)) {
					wording = messages[keys[i]];
				} else {
					if (messages == null) {
						messages = i18n.messages[locale][keys[i]];
					} else {
						messages = messages[keys[i]];
					}
				}
			}
		}
	}
	return wording;
};

const onlyNumber = function (event) {
	let keyCode = event.keyCode ? event.keyCode : event.which;
	if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
		// 46 is dot
		event.preventDefault();
	}
};

const onlyInteger = function (event) {
	let keyCode = event.keyCode ? event.keyCode : event.which;
	if (keyCode < 48 || keyCode > 57) {
		// 46 is dot
		event.preventDefault();
	}
};

const setRequiredErrorBorder = function (state, required, vuetify_style) {
	required = 0;
	let required_border = required == 1 ? (vuetify_style == 0 ? `border border-info` : `required-border`) : ``;
	let error_border = vuetify_style == 0 ? `border border-danger` : `error-border`;
	return state != false ? required_border : error_border;
};

const standardHeaderColWithoutDeliveryId = [
	{
		value: "main_salesman",
		text: i18n.t("COMMON.SUMMARY_COL.COL_MSM_CODE"),
		align: "center"
	},
	{
		value: "salesman",
		text: i18n.t("COMMON.SUMMARY_COL.COL_SM_CODE"),
		align: "center"
	},
	{
		value: "marking",
		text: i18n.t("COMMON.SUMMARY_COL.COL_MARKING"),
		align: "center"
	},
	{
		value: "full_marking",
		text: i18n.t("COMMON.SUMMARY_COL.COL_FULL_MARKING"),
		align: "center"
	},
	{
		value: "company_group",
		text: i18n.t("COMMON.SUMMARY_COL.COL_COMPANY_GRP"),
		align: "center",
		cellClass: "align-middle",
		sortable: false
	},
	{
		value: "customer_name",
		text: i18n.t("COMMON.SUMMARY_COL.COL_CUSTOMER_NAME"),
		align: "center",
		cellClass: "align-middle",
		sortable: false
	}
];

const standardHeaderColWithDeliveryId = [
	{
		value: "agent",
		text: i18n.t("COMMON.SUMMARY_COL.COL_AGENT"),
		align: "center text-nowrap"
	},
	{
		value: "main_salesman",
		text: i18n.t("COMMON.SUMMARY_COL.COL_MSM_CODE"),
		align: "center text-nowrap"
	},
	{
		value: "salesman",
		text: i18n.t("COMMON.SUMMARY_COL.COL_SM_CODE"),
		align: "center text-nowrap"
	},
	{
		value: "marking",
		text: i18n.t("COMMON.SUMMARY_COL.COL_MARKING"),
		align: "center text-nowrap"
	},
	{
		value: "full_marking",
		text: i18n.t("COMMON.SUMMARY_COL.COL_FULL_MARKING"),
		align: "center text-nowrap"
	},
	{
		value: "customer_name",
		text: i18n.t("COMMON.SUMMARY_COL.COL_CUSTOMER_NAME"),
		align: "center text-nowrap",
		cellClass: "align-middle",
		sortable: false
	},
	{
		value: "delivery_id",
		text: i18n.t("COMMON.SUMMARY_COL.COL_DELIVERY_ID"),
		align: "center text-nowrap",
		cellClass: "align-middle",
		sortable: false
	}
];

const checkFields = async function (form, formValid, type = 0) {
	let state = true;
	for (let key in form) {
		if (formValid.hasOwnProperty(key)) {
			let temp = (CoreSettingsJs.emptyObjectValidation(formValid[key].skip_required) || formValid[key].skip_required == 0) && CoreSettingsJs.emptyStringValidation(form[key]) ? false : true;

			formValid[key].state = temp;
			if (!temp) {
				state = false;
			}

			if (type == 1) {
				form[key + "_error"] = formValid[key].msg;
				form[key + "_state"] = state ? null : false;
			} else if (type == 2) {
				formValid[key].state = temp ? null : false;
			}
		}
	}
	return state;
};

const CurDate = function () {
	//Convert timestamp to milliseconds
	var date = new Date();
	// Year
	var year = date.getFullYear();
	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	// Day
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// Display date time in MM-dd-yyyy h:m:s format
	var finalDate = year + "-" + month + "-" + day;

	return finalDate;
};

const FormDateTime = function () {
	//Convert timestamp to milliseconds
	var date = new Date();
	// Year
	var year = date.getFullYear();
	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	// Day
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// Hours
	var hours = date.getHours();

	// Minutes
	var minutes = "0" + date.getMinutes();

	// Seconds
	var seconds = "0" + date.getSeconds();

	// Display date time in MM-dd-yyyy h:m:s format
	var datetime = year + "-" + month + "-" + day + " " + hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
	return datetime;
};

const CurDateTime = function () {
	//Convert timestamp to milliseconds
	var date = new Date();
	// Year
	var year = date.getFullYear();
	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	// Day
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// Hours
	var hours = date.getHours();

	// Minutes
	var minutes = "0" + date.getMinutes();

	// Seconds
	var seconds = "0" + date.getSeconds();

	// Display date time in MM-dd-yyyy h:m:s format
	var datetime = day + "/" + month + "/" + year + " " + hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
	return datetime;
};


const resetState = function (elem, state_var, idx = null) {
	if (idx != null) elem[idx][`${state_var}`] = null;
	elem[`${state_var}`] = null;
};

const onFormatterUpperCase = function (v, limit_length = 0) {
	if (v == null) return v;
	if (limit_length != 0) return v.slice(0, limit_length).toUpperCase();
	return v.trim().toUpperCase();
};

const getDefaultCountry = function () {
	return 127; // * default Malaysia id
};

const getSupplier = function () {
	return {
		supplier_id: null,
		driver_id: null,
		driver_name: null,
		driver_calling_code: null,
		driver_contact: null,
		name: null,
		calling_code: null,
		contact: null,
		fax_calling_code: null,
		fax: null,
		bank_acc_name: null,
		bank_acc_no: null,
		bank_address: null,
		bank_name: null
	};
};

const calculateTm3 = function (m3) {
	// * round to nearest 2 decimal places with 3 decimal points, last one being 0.
	return this.numberHandle(this.numberFormatCurrency(m3)).toFixed(3);
};

const calculateVw = function (m3) {
	// * calculate volume weight using tm3 value * 167.
	return this.calculateTm3(m3) * 167;
};

const placeHolderHandle = function (field_name) {
	return i18n.t("COMMON.PH_ENTER_TEXTBOX", {
		txt_fieldname: field_name
	});
};

const isMobile = function () {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		? true
		: false;
}

const unserializeJsonStr = function (value) {
	if (CoreSettingsJs.emptyStringValidation(value)) {
		return value;
	}
	return JSON.parse(value);
};

const dataTableHeightHandle = function (isForm = false) {

	let height = isMobile() ? 400 : 300;
	const _element = document.querySelector("#kt_content");

	if (_element != null) {
		const card_header = document.querySelector(isForm ? "#formHeader" : "#gsumary_card_header");
		const card_header_size = card_header != null ? Math.ceil(card_header.getBoundingClientRect().height) : 0;
		const dataTable = document.querySelector("div.vdatable");
		const preSiblingSize = dataTable != null ? Math.ceil(dataTable.previousElementSibling != null ? dataTable.previousElementSibling.getBoundingClientRect().height : 0) : 0;
		let gbizsContentSize = isMobile() ? Math.floor(window.screen.availHeight) : Math.floor(_element.getBoundingClientRect().height);

		let footerSize = 0;
		if (isMobile()) {
			gbizsContentSize -= parseInt(55);
			let winFooter = document.querySelector("#kt_footer");
			footerSize = winFooter != null ? Math.ceil(document.querySelector("#kt_footer").clientHeight) : 0;
			const footer = document.querySelector("div.v-data-footer");
			const vtblFooterSize = footer != null ? Math.ceil(document.querySelector("div.v-data-footer").clientHeight) : 0;
			gbizsContentSize -= (vtblFooterSize + footerSize);
			height = gbizsContentSize - (card_header_size + preSiblingSize)
		} else {


			footerSize = dataTable != null ? Math.ceil(dataTable.children[0].nextElementSibling != null ? dataTable.children[0].nextElementSibling.getBoundingClientRect().height : 0) : 0;
			height = gbizsContentSize - card_header_size - preSiblingSize - footerSize - 50;
		}
	}
	return height;
};
const summaryBodyHeightHandle = function () {
	const _element = document.querySelector("#kt_content");
	let height = isMobile() ? 400 : 600;
	if (_element != null) {
		const card_header = document.querySelector("#gsumary_card_header");
		const card_header_size = card_header != null ? Math.ceil(card_header.getBoundingClientRect().height) : 0;
		let content_size = isMobile() ? Math.floor(window.screen.availHeight) : Math.floor(_element.getBoundingClientRect().height);
		height = content_size - card_header_size;
	}
	return height;
}



const confirmationBox = async function (title = i18n.t("COMMON.MSG.ARE_YOU_SURE"), msg = "", icon = "info", cancel_btn = i18n.t("COMMON.BTN_CANCEL"), confirm_btn = i18n.t("COMMON.BTN_CONFIRM")) {
	let response = await Swal.fire({
		title: title,
		html: msg,
		icon: icon,
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		cancelButtonText: cancel_btn,
		confirmButtonText: confirm_btn
	});
	return response;
};

export default {
	calculateTm3,
	calculateVw,
	checkFields,
	checkResetSelection,
	checkResetSelectionObject,
	convertNestedObjectToNull,
	confirmationBox,
	resetObServer,
	createPage,
	CurDate,
	CurDateTime,
	dataTableHeightHandle,
	dimensionFormat,
	editorSettings,
	epo_status_options,
	filterActionField,
	getDefaultCountry,
	getIsOpenDesc,
	getStatusBadge,
	getStatusName,
	getSupplier,
	getTodayDate,
	getWordingFromi18n,
	getYesNo,
	getYesNoName,
	IntegerChecking,
	isMobile,
	locale_list,
	numberChecking,
	numberDecimal,
	numberFormatCurrency,
	numberFormatInteger,
	numberFormatM3,
	numberFormatToInteger,
	numberHandle,
	numberRemoveNegativeValue,
	onFormatterAccountNo,
	onFormatterPhoneNumber,
	onFormatterUpperCase,
	onlyInteger,
	onlyNumber,
	onPressPhoneNumber,
	placeHolderHandle,
	resetOptionsValue,
	resetState,
	selection_all,
	selection_options,
	setLanguageRecord,
	setRequiredErrorBorder,
	standardHeaderColWithDeliveryId,
	standardHeaderColWithoutDeliveryId,
	ucfirst,
	UnixTimeToDate,
	UnixTimeToDateTime,
	UnixTimeToYear,
	UnixTimeToYM,
	unserializeJsonStr,
	ucword,
	validateContactNo,
	validateEmail,
	viewPage,
	yes_no_options,
	yes_no_selection,
	summaryBodyHeightHandle,
	FormDateTime
};
